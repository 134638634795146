<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="编辑权限" width="600px" :close-on-click-modal="false" :before-close="closeTab">
      <el-form ref="formData" :model="formData" :rules="rules" :inline="false" size="small" label-width="100px">
        <el-form-item label="权限名称" prop="urName">
          <el-input v-model="formData.urName" clearable style="width: 300px"></el-input>
        </el-form-item>
        <!-- <el-form-item label="方法名" prop="uraAction">
          <el-input v-model="formData.uraAction" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="控制器" prop="uraController">
          <el-input v-model="formData.uraController" clearable style="width: 300px"></el-input>
        </el-form-item> -->
        <!-- 如果原先有值，则不可修改，无值则可以添加 -->
        <el-form-item label="Tag" prop="tag">
          <el-input v-model="formData.tag" clearable style="width: 300px" :disabled="!canEdit"></el-input>
        </el-form-item>
        <!-- <el-form-item label="请求类型" prop="type">
          <el-select v-model="formData.type" size="small">
            <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="状态">
          <el-select v-model="formData.uraStatus" style="width: 200px">
            <el-option value="1" label="启用"></el-option>
            <el-option value="0" label="禁用"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="关联Actions">
          <el-checkbox-group v-model="checkedActions">
            <el-checkbox v-for="item in formData.childrens" :label="item.umId" :key="item.umId">{{item.uraController +''+ item.uraAction}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { editQuanxian } from '@/api/privilegeManage'
export default {
  data() {
    return {
      formData: {
        // uraTitle: '',
        // uraAction: '',
        // uraController: '',
        // uraDisplay: '',
        // uraStatus: '',
        // usId: null,
        // uraId: null,
        // type: 'post',
        // tag: ''
        systemId: null,
        urName: '',
        umId: null,
        urId: null,
        tag: null
        // uraStatus: '',
        // childrens: []
      },
      // checkedActions: [],
      // typeList: ['post', 'get', 'put', 'delete'],
      rules: {
        urName: [validFn.required()],
        // uraAction: [validFn.required()],
        // uraController: [validFn.required()],
        tag: [validFn.enPoint()]
      },
      canEdit: true
    }
  },
  props: {
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      defaule: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        console.log(this.form)
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = this.form[key]
        })
        // this.checkedActions = this.form.childrens.map(item => item.umId)
        if (this.form.tag) {
          this.canEdit = false
        } else {
          this.canEdit = true
        }
      }
    }
  },
  methods: {
    closeTab() {
      this.$refs.formData.resetFields()
      this.$emit('update:visible', false)
    },
    submit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData)
          // editQuanxian().then(res => {
          //   if (res.code === '000000') {
          //     this.$message.success(res.message)
          //   }
          // }).catch(err => {
          //   console.log(err)
          // })
          this.loading = true
          editQuanxian(this.formData).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.message)
              this.closeTab()
              this.$emit('success')
            }
            this.loading = false
          }).catch(err => {
            console.log(err)
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
