<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" :title="title" width="900px" min-height="500px" :close-on-click-modal="false" :before-close="closeTab">
      <el-form ref="formData" inline :model="formData" :rules="formRules" size="small" label-width="100px">
        <el-form-item label="所属系统" prop="system">
          <el-input v-model="formData.system" style="width: 300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属页面" prop="umTitle">
          <el-input v-model="formData.umTitle" style="width: 300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="权限名称" prop="urName">
          <el-input v-model="formData.urName" clearable style="width: 300px" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="Tag" prop="tag">
          <el-input v-model="formData.tag" clearable style="width: 300px" placeholder="新增后不可修改"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="urDescription">
          <el-input type="textarea" v-model="formData.urDescription" clearable style="width: 300px" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- actions列表 -->
      <template>
        <el-divider>
          Actions
          <el-tooltip content="新增权限可同时新增action，创建成功后权限和action自动关联" effect="dark" placement="top">
            <svg-icon icon-class="question"></svg-icon>
          </el-tooltip>
        </el-divider>
        <div class="card_panel" v-for="(itemForm, index) in itemList" :key="index">
          <el-form ref="itemForm" :model="itemForm" inline size="small" label-width="100px" :rules="itemRules">
            <el-form-item label="模块" prop="uraModule">
              <el-input v-model="itemForm.uraModule" clearable style="width: 200px" placeholder="action以模块分组"></el-input>
            </el-form-item>
            <el-form-item label="标题" prop="uraTitle">
              <el-input v-model="itemForm.uraTitle" clearable style="width: 200px" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="方法名" prop="uraAction">
              <el-input v-model="itemForm.uraAction" clearable style="width: 200px" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="控制器" prop="uraController">
              <el-input v-model="itemForm.uraController" clearable style="width: 200px" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="请求类型" prop="method">
              <el-select v-model="itemForm.method" style="width: 200px" placeholder="请选择">
                <el-option v-for="item in typeList" :key="item" :value="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="uraStatus">
              <el-select v-model="itemForm.uraStatus" style="width: 200px">
                <el-option value="1" label="启用"></el-option>
                <el-option value="0" label="禁用"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公共Action?" prop="isPublic">
              <el-switch v-model="itemForm.isPublic" size="small" :active-value="1"
                :inactive-value="0" active-color="#13ce66"
                inactive-color="#ff4949"
                active
                :active-text="itemForm.isPublic ? '公共action为系统默认自带' : ''">
              </el-switch>
            </el-form-item>
          </el-form>
          <div class="btnOpt">
            <svg-icon icon-class="icon-add" @click="addItem"></svg-icon>
            <svg-icon v-if="itemList.length>1" icon-class="icon-del" @click="delItem(index)"></svg-icon>
          </div>
        </div>
      </template>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="loading" :disabled="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { deepClone } from '@/utils/index'
import { powerAction } from '@/api/url-config'
export default {
  data() {
    return {
      loading: false,
      formData: {
        system: '',
        systemId: null,
        umTitle: '',
        umId: null,
        urName: '',
        tag: null,
        urDescription: ''
      },
      title: '新增权限',
      itemForm: {
        uraModule: '',
        uraTitle: '',
        uraAction: '',
        uraController: '',
        method: 'post',
        uraStatus: '1',
        isPublic: '0'
      },
      typeList: ['post', 'get', 'put', 'delete'],
      itemList: [
        {
          uraModule: '',
          uraTitle: '',
          uraAction: '',
          uraController: '',
          method: 'post',
          uraStatus: '1',
          isPublic: '0'
        }
      ],
      formRules: {
        urName: [validFn.required()]
      },
      itemRules: {
        uraTitle: [validFn.required()],
        uraModule: [validFn.required()],
        uraAction: [validFn.required()],
        uraStatus: [validFn.required()],
        uraController: [validFn.required()]
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    umTitle: {
      type: String,
      default: ''
    },
    umId: {
      type: Number,
      default: null
    },
    system: {
      type: String,
      default: ''
    },
    systemId: {
      type: Number,
      default: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData.systemId = this.systemId
        this.formData.system = this.system
        this.formData.umTitle = this.umTitle
        this.formData.umId = this.umId
      }
    }
  },
  methods: {
    closeTab() {
      this.itemList = [
        {
          uraModule: '',
          uraTitle: '',
          uraAction: '',
          uraController: '',
          method: 'post',
          uraStatus: '1',
          isPublic: '0'
        }
      ]
      this.formData = {
        system: '',
        systemId: null,
        umTitle: '',
        umId: null,
        urName: '',
        tag: null,
        urDescription: ''
      }
      this.$emit('update:visible', false)
    },
    addItem() {
      const json = deepClone(this.itemForm)
      this.itemList.push(json)
    },
    delItem(index) {
      this.itemList.splice(index, 1)
    },
    submit() {
      const params = {
        userActionParamList: []
      }
      // 校验权限
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData)
          console.log(this.itemList)
          params.userRightInsertParam = {
            umId: this.formData.umId,
            usId: this.formData.systemId,
            tag: this.formData.tag,
            urDescription: this.formData.urDescription,
            urName: this.formData.urName
          }
        } else {
          return false
        }
      })
      // 校验actions
      // const len = this.itemList.length
      // let num = 0
      const validArr = [] // 校验通过的action
      console.log(this.$refs.itemForm)
      this.itemList.forEach((item, i) => {
        this.$refs.itemForm[i].validate(valid => {
          if (valid) {
            validArr.push(this.itemList[i])
          } else {
            return false
          }
        })
      })
      const actionList = validArr.map(item => {
        const json = deepClone(item)
        json.usId = this.systemId
        return json
      })
      params.userActionParamList = actionList

      console.log('参数')
      console.log(params)
      this.loading = true
      powerAction(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
          this.$emit('success')
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card_panel {
  width: 82.5%;
  padding: 10px;
  margin-left: 100px;
  margin-bottom: 10px;
  position: relative;
  border: 1px dashed#dcdfe6;
  .btnOpt {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    .svg-icon {
      width: 24px;
      height: 24px;
      border: 1px dashed #ff6600;
      color: #ff6600;
      &:nth-child(even) {
        margin-left: 4px;
      }
      &:hover {
        border-style: solid;
      }
    }
  }
}
</style>
